/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import * as styles from './layout.module.css'

const Disclaimer = () => {
  return (
    <div className={styles.disclaimer}>
        <p>Disclaimer: This post may have affiliate links, which means I may receive commissions if you choose to purchase through links I provide (at no extra cost to you). As an Amazon Associate I earn from qualifying purchases. Please read my disclosure for more info.
        </p>
    </div>
  )
}

export default Disclaimer
