import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import * as style from './layout.module.css'

const Layout2 = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)    
    return (
        <div className={style.container}>
            <nav>
                <ul className={style.navLinks}>
                    <li className={style.navLinkItem}>
                        <Link to="/" className={style.navLinkText}>
                            Home
                        </Link>
                    </li>
                    <li className={style.navLinkItem}>
                        <Link to="/about" className={style.navLinkText}> 
                            About
                        </Link>
                    </li>
                    <li className={style.navLinkItem}>
                        <Link to="/blog" className={style.navLinkText}> 
                            Blog
                        </Link>
                    </li>          
                    <li className={style.navLinkItem}>
                        <Link to="/contact" className={style.navLinkText}> 
                            Contact
                        </Link>
                    </li>   
                    <li className={style.navLinkItem}>
                        <Link to="/faq" className={style.navLinkText}> 
                            FAQ
                        </Link>
                    </li>                                                     
                </ul>
            </nav>
            <header className={style.siteTitle}>The Wandering Boy</header>
            <nav className={style.sticky}>
                <ul className={style.navLinksSecondary}>
                <li className={style.navLinkItem}>
                        <Link to="/books" className={style.navLinkText}> 
                            Books
                        </Link>
                    </li>
                    <li className={style.navLinkItem}>
                        <Link to="/plants" className={style.navLinkText}> 
                            Plants
                        </Link>
                    </li>
                    <li className={style.navLinkItem}>
                        <Link to="/adventures" className={style.navLinkText}> 
                            Adventures
                        </Link>
                    </li>          
                    <li className={style.navLinkItem}>
                        <Link to="/food" className={style.navLinkText}> 
                            Food
                        </Link>
                    </li>   
                    <li className={style.navLinkItem}>
                        <Link to="/coffee" className={style.navLinkText}> 
                            Coffee
                        </Link>
                    </li>  
                    <li className={style.navLinkItem}>
                        <Link to="/technology" className={style.navLinkText}> 
                            Technology
                        </Link>
                    </li>                                                     
                </ul>
            </nav>            
            <main className={style.main}>
                <h1 className={style.heading}>{pageTitle}</h1>
                {children}
            </main>
            <footer className={style.footer}>
                <div>Copyright © {new Date().getFullYear()} The Wandering Boy
                </div>
                <div><Link to="/disclosure-policy" className={style.navLinkText}>Disclosure & Privacy Policy</Link>
                </div>
            </footer>
        </div>
    )
}


export default Layout2